@import "../../../../assets/common.less";

.goods-cube-fill-container {
    width: 375px;
    margin: 0 auto;
    // margin-top: 8px;
    .column,.horizontal,.vertical {
        .img {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            // border-radius:8px;

        }
    }
    .column {
        justify-content: space-between;
        display: flex;
    }

    .horizontal {
        display: flex;
        justify-content: space-between;
        &.horizontal-2-2 {
            .right {
               .img:nth-last-child(1){
                    // margin-top: 8px;
               }
            }
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // margin-left: 1px;
        }
    }

    .vertical {
        .top {
            // margin-bottom: 8px;

        }
        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}


.goods-cube-fill-set {
    padding-bottom: 20px;

    .set-form {
        padding: 0 10px;
    }

    .image-list {
        padding: 0 10px;

        .image-item {
            border-radius: 2px;
            overflow: hidden;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            margin-bottom: 10px;

            .img {
                height: 135px;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                background-color: #f2f2f2;
                // .sort{
                //     width: 20px;
                //     height: 20px;
                //     border-radius:50%;
                //     color:#fff;
                //     font-size:12px;

                //     background-color: rgba(0,0,0,0.6);
                //     z-index: 2;
                //     text-align: center;
                //     line-height: 20px;
                // }
                .change-img {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    line-height: 25px;
                    background-color: rgba(0, 0, 0, 0.3);
                    margin-bottom: 5px;
                    position: absolute;
                    top: 10px;
                    z-index: 2;
                    left: 10px;
                    cursor: pointer;
                }

                .operation {
                    position: absolute;
                    top: 0px;
                    z-index: 2;
                    right: 10px;
                    padding-top: 10px;

                    .icon {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                        line-height: 25px;
                        background-color: rgba(0, 0, 0, 0.3);
                        margin-bottom: 5px;
                        cursor: pointer;
                    }
                }

                .img-mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .info {
                padding: 10px;

                .input-group {
                    .input-item {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    .dragger-con {
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .add-area {
        .icon {
            font-size: 30px;
            color: #333;

        }

        .desc {
            font-size: 12px;
            color: #999;
            margin-top: 10px;
        }
    }

    .style-list-con {
        margin-top: -20px;
        margin-bottom: 10px;

        .list-con {
            display: flex;
            justify-content:flex-start;
            align-items: center;
            flex-wrap: wrap;
            word-break: break-all;

            .list-item {
                cursor: pointer;
                margin-right: 15px;
                .item-container {
                    border: 2px solid #eee;
                    background-color: #f0f0f0;
                    margin-bottom: 4px;
                    border-radius:4px;

                    &.selected {
                        border-color: #1890ff;
                    }

                    // padding: 4px;
                    // border

                    .column,
                    .horizontal,
                    .vertical {
                        padding: 4px;

                        .block {
                            background-color: #ccc;
                            border-radius:4px;
                        }
                    }

                    .column {
                        display: flex;

                        .block {
                            margin-right: 4px;

                            &:nth-last-child(1) {
                                margin-right: 0;
                            }
                        }
                    }

                    .horizontal {
                        display: flex;

                        &.horizontal-2-2 {
                            .right {
                                .block {
                                    &:nth-last-child(1) {
                                        margin-top: 1px;
                                    }
                                }
                            }
                        }

                        .left {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            // justify-content: space-between;
                            .block {}
                        }

                        .right {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin-left: 1px;

                            .block {}

                            // justify-content: space-between;
                        }
                    }

                    .vertical {
                        .top {
                            margin-bottom: 2px;

                        }

                        .bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }


                }
            }
        }
    }
}
