.mantissa .ant-form-item {
   margin-bottom: 0px !important;
}

.adv-img .img {
   width: 60px;
   height: 60px;
}
.wid100 {
   width: 100%;
}

.wid300 {
    width: 500px;
}

