.decorate-edit-container{
    height: 95vh;
    background-color: #fff;
    .loading{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 80px;
    }
    .page-loading{
        width: 100%;
        height: 100%;
        position:absolute;
        top:0;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        background-color: rgba(0,0,0,0.2);
    }
    .page-header{
        background-color: #fff;
        // margin:0px 10px;
        margin-bottom: 0;
    }
    .decorate-container{
        padding: 10px;
        height: 92%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
    
}
.copy-link {
    color: #1890ff;
    cursor: pointer;
}
