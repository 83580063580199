.price-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    font-family: emoji;

    .noborder {
        border-top: none !important;
    }

    .fontweight {
        font-weight: 600;
    }

    .section {
        display: flex;
        flex-direction: row;
        border: 1px solid #efefef;
        border-right: none;
        border-bottom: none;
        .left-col {
            width: 15%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-right: 1px solid #efefef;
            display: flex;
            .title {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #efefef;
                font-size: 14px;
            }
            .name {
                width: 100%;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #efefef;
            }

        }

        .right-col {
            flex: 1;
            display: flex;
            flex-direction: column;

            .right-col-wrapper {
                display: flex;
                flex-direction: row;
                width: 100%;

                .info-left {
                    display: flex;
                    width: 20%;
                   

                    .name-col {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        // border-top: 1px solid #efefef;
                        border-right: 1px solid #efefef;
                        .title {
                            width: 100%;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-bottom: 1px solid #efefef;
                        }
            
                        .name {
                            width: 100%;
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-bottom: 1px solid #efefef;
                        }
                    }
            
                }

                .info-right {
                    display: flex;
                    flex: 1;

                    .info-row {
                        width: 100%;
                        justify-content: space-between;

                        .info-row-col {
                            height: 50px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            border-right: 1px solid #efefef;
                            border-bottom: 1px solid #efefef;

                            .discount {
                                color: #88888d;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

       

        .border-btm {
            border-bottom: 1px solid #efefef;
        }
        .border-nonetop {
            border-top: none !important;
        }
        .border-nonebtm {
            border-bottom: none !important; 
        }

    }
}
