@import '../../../node_modules/antd/lib/style/themes/default.less';

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);
.right-content{
  .menu {
    :global(.anticon) {
      margin-right: 8px;
    }
    :global(.ant-dropdown-menu-item) {
      min-width: 160px;
    }
  }
  .user-info{
    display: flex;
    align-items: center;
    .name{
      margin-left: 5px;
      cursor: pointer;
      font-size:13px;
    }
  }
  
  .container.right {
    display: flex;
    float: right;
    height: 48px;
    margin-left: auto;
    overflow: hidden;
    .action {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 12px;
      cursor: pointer;
      transition: all 0.3s;
  
      &:hover {
        background: @pro-header-hover-bg;
      }
      &:global(.opened) {
        background: @pro-header-hover-bg;
      }
    }
    .search {
      padding: 0 12px;
      &:hover {
        background: transparent;
      }
    }
    .account {
      .avatar {
        margin-right: 8px;
        color: @primary-color;
        vertical-align: top;
        background: rgba(255, 255, 255, 0.85);
      }
    }
  }
  
  .dark {
    .action {
      &:hover {
        background: #252a3d;
      }
      &:global(.opened) {
        background: #252a3d;
      }
    }
  }
}


@media only screen and (max-width: @screen-md) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
    .account {
      .avatar {
        margin-right: 0;
      }
    }
    .search {
      display: none;
    }
  }
}

