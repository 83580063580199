.editor-box--1{
    border: 2px dashed #F1F1F1;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    .public-DraftEditor-content{
        border: 1px solid #eee;
    }
}
.ant-form-item-label {
    flex: 0 !important;
}

.list_titleBox {
    position: relative;

    .xian {
        border-bottom: 4px dashed #ccc;
        position: absolute;
        top: 94px;
        left: 100%;
        width: 498%;
    }
}
.balanceBox{
    padding: 6px 20px;
    border-radius: 4px;
    background: #ecf8e7;
    color: #53c21d;
    margin-bottom: 34px;
    margin-top: -10px;
}

.mapBox{
    display: flex;
    width: 940px;
    flex-wrap: wrap;
    justify-content: space-between;
    word-break: break-all;
    .topBox{
        width: 100%;
    }
    .map{
        width: 600px;
        height: 500px;
    }
    .searchBox{
        width: 300px;
        .ant-list-items{
            height: 410px;
            overflow-y: scroll;
            .listItemBox{
                border-bottom: 1px solid #eee;
                box-sizing: border-box;
                padding: 10px;
                cursor: pointer;
                .listName{
                    font-weight: bold;
                    font-size: 14px;
                    color: #222;
                    margin-bottom: 4px;
                    
                }
                
                .listType, .listAddress{
                    font-size: 12px;
                    color: #666;
                    margin-bottom: 4px;
                }
            }
            .listItemBoxSelected{
                background: #eee;
            }
        }
    }
}
.trainList-details-bodList{
    .ant-form-item{
        max-width: 200px;
    }
    .ant-pro-table-list-toolbar-container{
        justify-content: flex-start;
    }
    .ant-pro-table-list-toolbar-right{
        justify-content: space-between;
        width: 100%;
    }
}

.selectPimTable  {
    .ant-form-item {
        margin-bottom:  0px !important;
    }
   
}
