@import "../../../../assets/common.less";






.setting-container{
    padding-bottom: 20px;
    .goods-tabs{
        .ant-tabs-nav-wrap {
            padding-left: 0px;
        }
    }
    .set-con{
        padding-left: 20px;
    }
    .goods-set-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        padding-bottom: 10px;
        border-bottom:1px solid #eee;
        .left{
            color:#666;
            font-size:14px;
        }
    }
    .goods-set-list{
        padding: 0 10px;
       
        .goods-set-item{
            overflow: hidden;
            display: flex;
            position:relative;
            border-bottom:1px solid #eee;
            padding: 10px 0;
            &:nth-last-child(1){
                border:none;
            }
            .img{
                width: 100px;
                height: 100px;
                overflow: hidden;
                position:relative;
                border-radius:4px;
                display: flex;
                align-items: center;
                background-color: #f2f2f2;
                .number{
                    position:absolute;
                    left:5px;
                    top:5px;
                    z-index: 2;
                    min-width: 25px;
                    height: 25px;
                    padding: 0 4px;
                    border-radius:12px;
                    color:#fff;
                    font-size:14px;
                    text-align: center;
                    line-height: 25px;
                    background-color: rgba(0,0,0,0.6);
                    margin-bottom: 11px;
                }
            }
            .info{
                width: 200px;  
                padding-left: 10px;
                position:relative;
                .name{
                    font-size:13px;
                    line-height: 20px;
                    margin-bottom: 6px;

                }           
                .price{
                    font-size:13px;
                    font-weight: bold;
                    color:#EA1F36;
                    position:absolute;
                    bottom:15px;
                    left:10px;
                    margin-bottom: 0;
                }   
            }
            .operation{
                position:absolute;
                top:0px;
                z-index: 2;
                right:0px;
                padding-top: 10px;
                .icon{
                    width: 25px;
                    height: 25px;
                    border-radius:50%;
                    color:#fff;
                    font-size:14px;
                    text-align: center;
                    line-height: 25px;
                    background-color: rgba(0,0,0,0.3);
                    margin-bottom: 11px;
                }
            }
        }
    }
    .dragger-con{
        padding: 0 10px;
        margin-bottom: 10px;
    }
    .add-area{
        .icon{
            font-size:30px;
            color:#333;

        }
        .desc{
            font-size:12px;
            color:#999;
            margin-top: 10px;
        }
    }
}
