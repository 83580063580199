.withdrawalBox{
    background: #fff;
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
    min-height: 91vh;
    overflow: hidden;
}
.withdrawalTabBox{
    margin-top: 30px;
}
