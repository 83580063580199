@mainColor: #EA1F36;

*,p,div{
    padding: 0;
    margin: 0;
}
.clearfix::after,
.clearfix::before {
    content: "";
    display: table;
    clear: both;
}

.text-1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
}

.text-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
}