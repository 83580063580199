@import "../../../../assets/common.less";

.auxiliary-line-container{
    // width: 355px;
    // margin:0 auto;
    // padding: 4px 0;

    .line-{
        border-bottom:1px solid #eee;
    }
}

.auxiliary-line-setting{
    .set-color-hover{
        width: 32px;
        height: 32px;
        background-color: #000000;
        border:2px solid #ccc;
        border-radius:2px; 
    }
}
