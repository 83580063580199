@import "../../../../assets/common.less";

.tab-nav-container {

    width: 100%;
    overflow-x: scroll;
    
    .image-ad-list {
        // width: 355px;
        // min-height: 120px;
        // margin:0 auto;
        display: flex;
        flex-wrap: nowrap;
        padding: 6px 16px;

        .nav-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 20px;

            &:nth-last-child(1) {
                margin-right: 0;
            }

            .img {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .txt {
                font-size: 12px;
                color: #797A80;
                line-height: 17px;
                max-width: 50px;
                margin-top: 2px;
            }
        }
    }
}


.tab-nav-set {
    padding-bottom: 20px;

    .set-form {
        padding: 0 10px;
    }

    .image-list {
        padding: 0 10px;

        .image-item {
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.06);
            margin-bottom: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            // padding: 10px 0;
            // padding-bottom: 5px;
            // border-bottom: 1px solid #eee;
            

            .top-con {

                display: flex;
                align-items: flex-start;

                .img {
                    height: 80px;
                    overflow: hidden;
                    position: relative;
                    display: flex;
                    background-color: #f2f2f2;
                    // .sort{
                    //     width: 20px;
                    //     height: 20px;
                    //     border-radius:50%;
                    //     color:#fff;
                    //     font-size:12px;

                    //     background-color: rgba(0,0,0,0.6);
                    //     z-index: 2;
                    //     text-align: center;
                    //     line-height: 20px;
                    // }
                    .change-img {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                        line-height: 25px;
                        background-color: rgba(0, 0, 0, 0.3);
                        margin-bottom: 5px;
                        position: absolute;
                        top: 5px;
                        z-index: 2;
                        left: 5px;
                        cursor: pointer;
                    }

                    .img-mask {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .info {
                    padding: 0 10px;

                    .input-group {
                        .input-item {
                            display: flex;
                            align-items: center;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .operation {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 10px;
                .icon {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    color: #444;
                    font-size: 14px;
                    text-align: center;
                    line-height: 25px;
                    // background-color: rgba(0,0,0,0.3);
                    // margin-bottom: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    .dragger-con {
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .add-area {
        .icon {
            font-size: 30px;
            color: #333;

        }

        .desc {
            font-size: 12px;
            color: #999;
            margin-top: 10px;
        }
    }
}
