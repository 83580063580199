.preview-container{
    text-align: center;
    width: 355px;
    background-color: #fff;
    line-height: 100px;
    margin: 5px auto ;
    border-radius:8px;
    font-weight: bold;

}
