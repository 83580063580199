.mantissa .ant-form-item {
   margin-bottom: 0px !important;
}

.adv-img .img {
   width: 60px;
   height: 60px;
}

.wid100 {
   width: 100%;
}

.ant-table-row-expand-icon-cell {
   padding-left: 40px !important;
   box-sizing: border-box;
}

//  .ant-table-expanded-row {
//    display: none !important;   
// }

.left20 {
   margin: 40px;
}

.dealer-title {
   position: absolute;
   left: 0px;
   bottom: 20px;
   font-size: 14px;

   span {
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
   }
}

