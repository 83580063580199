.option-wrapper {
    font-weight: 400;
    width: 98%;
    margin: 0px 0px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin: 10px 0px;
}
.option-title {
    font-size: 16px;
    margin-right: 10px;

}
.answer-title {
    font-size: 16px;
    margin: 20px 0px 10px;
}
.options-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.sequence-name {
    font-size: 18px;
    margin-right: 10px;
}

.sequence-container {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    box-sizing: border-box;
}

.xx-options {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.right-key {
    display: flex;
    flex-direction: column;
}

.review-title {
    font-size: 16px;
}
.radio-group {
    display: flex;
    flex-direction: column;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
}
