@import '~antd/dist/antd.less';

.-input-prefix {
  color: #595959;
}

.loading-bar {
  height: 3px;
  background-color: #009cd8;
  position: absolute;
  top: 0;
  z-index: 1031;
}

/*修复扩展面板中，antd把descriptions的宽度设为auto*/
.expanded-row {
  .ant-descriptions .ant-descriptions-view table {
    width: 100% !important;
  }
}

.ant-descriptions-item-label {
  font-weight: bold !important;
  color: #595959 !important;
}

.toolbar {
  padding-bottom: 5px;
}

/**
排列
 */
.text-center {
  text-align: center !important;
}

;

.text-right {
  text-align: right !important;
}

;

.text-left {
  text-align: left;
}

.wrap {
  white-space: pre-line;
}

.errorRow .tableTh {
  color: #fff !important;
  font-weight: bold;
  background: #ff6351 !important;
}

.errorRow {
  .ant-btn-link {
    color: #ff4d4f !important;
  }

  .svgColor {
    color: #ff4d4f !important;
    display: none !important;
    display: inline-block !important;
  }

}

.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ccc !important;
}





.text-1 {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.text-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.x_c {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.y_c {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ant-upload-draggable-list-item {
  width: 100%;
  height: 100%;
}

// .c{
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
