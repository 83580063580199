.codeRegion{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .codeBox{
        width: 100%;
        font-size: 12px;
        border-bottom: 1px dashed #ccc;
        // border-right: 1px dashed #ccc;
        padding-bottom: 10px;
        padding: 10px;
        box-sizing: border-box;
    }
    .noneX{
        border-right: none;
    }
}
.exchangeGoodsOrder{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    line-height: 30px;
    margin-bottom: 20px;
    p{
        width: 25%;
        color: #777;
    };
    span{
        color: #000;
    }
    h3{
        width: 100%;
        font-weight:bold;
        color: #333;
    }
}
.allPrice{
    text-align: right;
    padding: 8px 12px;
    box-sizing: border-box;
    span{
        margin-left: 40px;
    }
    .FK{
        font-size: 24px;
        color: #000;
    }
    .FKtext{
        margin-left:0px;
        font-size: 24px;
        color: #ee2222;
        font-weight: bold;
    }
}

.totalPriceAll{
    width: 100%;
    text-align: right;
    span{
        color: #ee2222;
        font-weight: bold;
    }
}
