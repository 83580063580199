.menu-header {
    display: flex;
    align-items: center;

    .img {
        // width: 32px;
        // height: 32px;
        // border-radius:50%;
        // overflow: hidden;
        // border:2px solid #fff;
        width: 90%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        margin-bottom: 0;

        h1 {
            margin-left: 0;
        }
    }
}

.switch-tenant {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 5px;
}
