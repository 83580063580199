.home-page-box {
  width: 100%;
  min-height: 100vh;
  background: #fff;
}
.home-page-box h2 {
  text-align: center;
  line-height: 80vh;
}

.status-filter {
  position: absolute;
  bottom: 15px;
}
.left300{
  padding: 0 30px !important;
  box-sizing: border-box;
}

.modifyDealer {
  .content_top, .ant-pro-page-container-warp {
     display: none !important;
  }
  .content {
    padding: 0px !important;
    background-color: #fff !important;
  }
  .content_center {
    margin-top: 0px !important;
  }
  .ant-pro-card-body {
    margin: -20px -20px -14px !important;
    padding: 0px !important;
  }
  .ant-form-item {
    margin-bottom: 16px !important;
  }
  .borderBtm {
    border-bottom: 1px solid #efefef; 
    margin-bottom: 20px;
 }
}

.bind-recard-title {
  position: absolute;
  left: 0px;
  bottom: 10px;
}

.search-wrapper {
  background-color: #fff;
  padding: 20px;
}
.gray {
  color: #333;
  &:hover {
    color: #333;
  }
}


