@import "../../../../assets/common.less";

.image-ad-container{
    width: 375px;
    margin:0 auto;
    
    .image-ad-list{
        // min-height: 120px;
        display: flex;
        line-height: 0;
        
        &.column-1{

            .image-ad-item{
                width: 100%;

            &:nth-child(1){
                margin-right: 0!important;
            }
            }
        }
        &.column-2{
            
            .image-ad-item{
                width: 50%;
                &:nth-child(2){
                    margin-right: 0!important;
                }
            }
        }
        &.column-3{
            .image-ad-item{
                width: 33.3333%;

                &:nth-child(3){
                    margin-right: 0!important;
                }
            }
        }
        &.column-4{
            .image-ad-item{
                width: 25%;

                &:nth-child(4){
                    margin-right: 0!important;
                }
            }
        }
        &.column-5{
            .image-ad-item{
                width: 20%;

                &:nth-child(5){
                    margin-right: 0!important;
                }
            }
        }
        &.column-6{
            .image-ad-item{
                width: 16.6666%;

                &:nth-child(6){
                    margin-right: 0!important;
                }
            }
        }
        .image-ad-item{
           
            // flex:1;
            .ant-imag,.i{
                float: left;
            }
        }
    }
}


.image-ad-set{
    padding-bottom: 20px;
    .set-form{
        padding: 0 10px;
    }
    .image-list{
        padding: 0 10px;
        .image-item{
            border-radius:2px;
            overflow: hidden;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
            margin-bottom: 10px;
            .img{
                height: 135px;
                overflow: hidden;
                position:relative;
                display: flex;
                align-items: center;
                background-color: #f2f2f2;
                // .sort{
                //     width: 20px;
                //     height: 20px;
                //     border-radius:50%;
                //     color:#fff;
                //     font-size:12px;
                    
                //     background-color: rgba(0,0,0,0.6);
                //     z-index: 2;
                //     text-align: center;
                //     line-height: 20px;
                // }
                .change-img{
                    width: 25px;
                    height: 25px;
                    border-radius:50%;
                    color:#fff;
                    font-size:14px;
                    text-align: center;
                    line-height: 25px;
                    background-color: rgba(0,0,0,0.3);
                    margin-bottom: 5px;
                    position:absolute;
                    top:10px;
                    z-index: 2;
                    left:10px;
                    cursor: pointer;
                }
                .operation{
                    position:absolute;
                    top:0px;
                    z-index: 2;
                    right:10px;
                    padding-top: 10px;
                    .icon{
                        width: 25px;
                        height: 25px;
                        border-radius:50%;
                        color:#fff;
                        font-size:14px;
                        text-align: center;
                        line-height: 25px;
                        background-color: rgba(0,0,0,0.3);
                        margin-bottom: 5px;
                        cursor: pointer;
                    }
                }
                .img-mask{
                    position:absolute;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                }
            }
            .info{
                padding: 10px;
                .input-group{
                    .input-item{
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
    .dragger-con{
        padding: 0 10px;
        margin-bottom: 10px;
    }
    .add-area{
        .icon{
            font-size:30px;
            color:#333;

        }
        .desc{
            font-size:12px;
            color:#999;
            margin-top: 10px;
        }
    }
}
