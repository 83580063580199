@import '~antd/lib/style/themes/default.less';

.pageName {
  color: #333;
  font-weight: bold;
}

.ant-pro-page-container-children-content {
  margin: 0px; // 0px 0px;

  //.ant-pro-table{
  //  .ant-card {
  //    .ant-car-body {
  //      padding: 2px;
  //    }
  //  }
  //}
}

.ListTime {
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: -52px;
  margin-right: 20px;
  z-index: 1;
  position: relative;
  padding-left: 31px;
  font-size: 16px;
  width: 500px;
}

.ant-page-header-heading {
  .ant-form-item-control-input-content {
    margin-right: 16px;
    // margin-bottom: 20px;
  }
}

.content {
  background: #f7f8fa;
  overflow: hidden;
  position: relative;
  padding: 16px;
  box-sizing: border-box;

  .content_search {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }

  .content_top {
    position: absolute;
    margin-left: -20px;
    top: 12px;
    left: 38px;
    background: #fff;
    z-index: 10;
    padding: 15px 16px 10px;
    width: 98%;
    // z-index: 99;
    // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .box {
      display: flex;
      width: 100%;

      .ant-tabs {
        width: 100%;

        .ant-tabs-nav {
          margin-bottom: 1vh;
        }
      }

    }

    .deleteAll {
      margin-left: 30px;
    }

    .ant-tabs-top>.ant-tabs-nav::before {
      border: none !important;
    }

    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
      margin-left: 0px !important;
      margin-right: 12px;
    }
  }

  .marginTop0 {
    margin-top: 0px !important;
  }


  .content_center {
    margin-top: 69px;
    .ant-pro-card {
      background: #f7f8fa;
    }

    .ant-pro-card-body {
      margin-top: 16px;
      background: #fff;
      padding: 20px !important;
      box-sizing: border-box;

      .ant-pro-table-list-toolbar {
        background: #fff;
      }
    }

    .ant-page-header {
      padding: 16px;

      .ant-page-header-heading-left {
        display: none;
      }

      .ant-page-header-heading {
        justify-content: left !important;
      }
    }

  }
}

.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
  padding: 4px 8px !important;
  font-size: 12px !important;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
  background: #1890ff;

  .ant-tabs-tab-btn {
    color: #fff;
  }

  .anticon-close {
    color: #fff !important;
  }
}

.page_box {
  width: 100%;
  background: #fff;
  box-shadow: 0 -3px 4px rgba(0, 21, 41, 0.08);
  padding: 14px 20px;
  box-sizing: border-box;

  .ant-pagination {
    display: flex;

    .ant-pagination-total-text {
      margin-right: auto;
    }
  }
}

.goTop {
  margin-left: auto;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.3);
}

.goTop:hover {
  background: #1890ff;
}
