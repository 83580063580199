@import "../../../../assets/common.less";

.auxiliary-blank-container{
    width: 355px;
    margin:0 auto;
    // padding: 4px 0;

    .blank-{
        height: 20px;
        // border-bottom:1px solid #eee;
    }
}

.auxiliary-blank-setting{
    .set-color-hover{
        width: 32px;
        height: 32px;
        background-color: #000000;
        border:2px solid #ccc;
        border-radius:2px; 
    }
}
