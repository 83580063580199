.utitle {
    font-size: 14px;
    position: relative;
    padding-left: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;

    &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 16px;
        background-color: #1890ff;
        top: 0px;
        bottom: 0px;
        margin: auto;
        left: 0px;
    }
}

.bstitle {
    font-size: 14px;
    margin-bottom: 5px;
}

.mtop {
    margin-top: 40px;
}

.link {
    color: #1890ff;
    cursor: pointer;
}
