@import "../../../assets/common.less";

.goods-container {
  .item {
    background-color: #fff;
    width: 174px;
    margin-bottom: 8px;

    border-radius: 8px;
    overflow: hidden;

    &.scroll-item {
      width: 100px;
      margin-right: 8px;

      .img {
        width: 100px;
        height: 100px;
      }

      .info {
        padding: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
          display: none;
        }

        .price {
          color: #fff;
          height: 20px;
          padding: 0 8px;
          border-radius: 10px;
          background-color: #EA1F36;
          font-weight: bold;
          display: flex;
          align-items: center;

          .l {
            line-height: 14px;
            font-size: 12px;
            margin-top: 2px;
          }

          .c {
            font-size: 14px;
            line-height: 16px;
            margin-left: 1px;
          }

          .r {
            line-height: 14px;
            font-size: 12px;
            margin-top: 2px;
          }
        }

        .origin {
          font-size: 12px;
          color: #d0d0d6;
          line-height: 17px;
          text-decoration: line-through;
        }
      }
    }

    &.big-img-item {
      width: 100%;

      .img {
        width: 100%;
        height: 150px;
        border-radius: 8px 8px 0 0;
      }

      .info {
        position: relative;
        padding-bottom: 20px;

        .buy-btn {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
    }

    &.list-item {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .img {
        width: 140px;
        height: 140px;
        border-radius: 8px;
      }

      .info {
        width: 220px;
        height: 140px;
        position: relative;

        .name {
          font-size: 14px;
          line-height: 22px;
        }

        .price {
          position: absolute;
          bottom: 15px;
          left: 20px;
        }
      }
    }

    &.collageList-item {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .img {
        width: 140px;
        height: 140px;
        border-radius: 8px;
      }
      .dumpling-box{
        padding: 0 4px;
        font-size: 12px;
        border: 1px solid #FE5366;
        border-radius: 4px;
        display: inline-block;
        color: #FE5366;
      }

      .info {
        width: 220px;
        height: 140px;
        position: relative;

        .name {
          font-size: 14px;
          line-height: 22px;
        }

        .price {
          position: absolute;
          width: 202px;
          bottom: 0px;
          left: 8px;
          height: 36px;
          line-height: 36px;
          display: flex;
          background-image: url('../../../assets/images/img_pintuan_price_bg@2x.png');
          background-size: 100% 100%;
          .original{
            text-decoration:line-through;
            color: #FFFFFF;
            font-weight: 100;
            margin-left: 8px;
            line-height: 30px;
          }
          .retailPrice{
            color: white;
            display: flex;
            height: 36px;
            margin-left: 10px;
            span{
              font-size: 12px;
              line-height: 40px;
              margin-top: 10px;
              margin-top: 3px;
            }
            .num{
              margin-top: 0;
              font-size: 20px;
            }
          }
          .text{
            color: #5F3504;
            margin-left: auto;
            width: 50px;
          }
        }
      }
    }

    .img {
      width: 174px;
      height: 174px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      overflow: hidden;

      .i {}
    }

    .info {
      padding: 8px;

      .name {
        font-size: 14px;
        color: #101012;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .price {
        color: @mainColor;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        margin-top: 4px;

        .l,
        .r {
          line-height: 19px;
        }

        .c {
          font-size: 18px;
          line-height: 21px;
          margin-left: 2px;
        }
      }
    }
  }

  .goods-list {
    padding: 0 9px;
    padding-top: 8px;

    .left {
      float: left;

    }

    .right {
      float: right;
    }

  }

  .scroll-con {
    overflow-x: scroll;
    width: 355px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 4px;

    &.is-group {
      border-radius: 0 0 12px 12px;
    }

    .scroll-list {
      display: flex;
      flex-wrap: nowrap;
      min-width: 375px;
      padding: 0 8px;
    }

  }
}
