.addpage-type {
    .ant-modal-footer {
        display: none !important;
    }
    .ant-modal-body {
        padding-top: 10px !important;
    }
}

.operation {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
