::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
  border-radius: 2px;
}

/*定义滚动条轨道内阴影+圆角*/

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #000;
  border-radius: 10px;
  background-color: #eee;
}

/*定义滑块内阴影+圆角*/

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
  background-color: rgba(0, 0, 0, 0.4);
  // display: none;
}

.modules-list {
    // width: 30%;
    background-color: #fff;
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    width: 300px;

    .modules-title {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        padding: 10px;
        z-index: 2;
    }

    .list-con {

        max-height: 90vh;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        word-break: break-all;
        align-items: flex-start;
        justify-content: space-between;

        .module-item {
            // margin-right: 10px;
            margin-bottom: 10px;
            width: 47%;
            display: inline-block;
            cursor: grab;

        }
    }
}

.page-preview {
    background-color: #fff;
    // min-width: 560px;
    // height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0 10px;

    .page-view {
        width: 431px;
        position: relative;
        // box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
        height: 95%;
        overflow-y: scroll;
        box-sizing: border-box;
        user-select: none;
        .mobile-bg {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            user-select: none;
        }

        .popup-preview {
            .popup-mask {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.4);
                z-index: 3;
                width: 100%;
                height: 100%;

                .img-con {
                    position: absolute;
                    top: 15%;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    width: 320px;

                    .img {}

                    .close {
                        display: flex;
                        justify-content: center;
                        margin-top: 4px;
                        // position: absolute;
                        // bottom: -40px;
                        // left: 50%;
                        // transform: translate(-50%, 0%);
                        // cursor: pointer;

                        .icon {
                            color: #fff;
                            font-size: 28px;
                        }
                    }
                }
            }
        }

        .module-item {
            position: relative;
            cursor: pointer;

            .hover-border {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 98%;
                height: 98%;
                border: 2px dashed #1890ff;
                // z-index: 2;
                display: none;
                box-sizing: content-box;
                z-index: 2;
            }

            &:hover {

                .hover-border,
                .operation-con {
                    display: block;
                    opacity: 0.6;
                }

            }

            &.selected {

                .hover-border,
                .operation-con {
                    display: block;
                    opacity: 1;
                }
                
            }

            .operation-con {
                z-index: 2;
                display: none;
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translate(0, -50%);

                &.top {
                    transform: none;
                    top: 15px;
                }

                .icon-opera {
                    margin-left: 10px;
                }

                .delete {
                    // position: absolute;
                    // right: 5px;
                    // top: 50%;
                    // transform: translate(0, -50%);
                }

                .copy {
                    // position: absolute;
                    // right: 45px;
                    // top: 50%;
                    // transform: translate(0, -50%);
                }
            }

            &.hover {
                padding-top: 150px;

                &::after {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 355px;
                    height: 130px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    border: 2px dashed #1890ff;
                }
            }
        }

        .insert-box {
            width: 355px;
            height: 130px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 2px dashed #1890ff;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    .header {
        padding-top: 32px;
        position: absolute;
        top: 26px;
        left: 28px;
        width: 375px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.03);
        background-color: #fefefe;
        border-radius: 39px 39px 0 0;
        z-index: 3;

        .con {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            padding: 0 15px;
            position: relative;

            .back {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 15px;
            }

            .txt {
                max-width: 240px;
                margin-bottom: 0;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .page-container {

        width: 375px;
        border-radius: 39px;
        margin-left: 28px;
        margin-top: 26px;
        // min-height: 100%;
        height: 812px;
        overflow-y: scroll;
        background-color: #f9f9f9;
        padding-top: 76px;
        position: relative;
        z-index: 2;
        padding-bottom: 40px;

        &:hover{
            &::-webkit-scrollbar-thumb{
                display: block;
            }
        }

        &.dis-scroll {
            overflow: hidden;
        }

        img {
            width: 100%;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;

        .con {
            display: flex;
            align-items: center;
        }
    }
}
.page-preview ::-webkit-scrollbar {
  width: 0px;
}

.modules-setting {
    background-color: #fff;
    width: 400px;
    // height: 90vh;
    overflow-y: scroll;
    box-sizing: border-box;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 0 10px;
    position: relative;
    // padding-top: 100px;

    // padding: 10px;
    .custom-tabs {
        .ant-tabs-nav-wrap {
            padding-left: 15px;
        }
    }

    .module-item {
        position: relative;
        height: 44px;
        overflow: hidden;
        // background-color: red;
        transition: height .3s ease-in-out;

        &.expand {
            height: auto;
        }

        &.dragging {
            opacity: 0;
        }

        &.selected {
            border: 1px solid rgba(255, 117, 11, 0.6);
        }

        .module-header {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #efefef;
            padding: 0 10px;
            position: relative;
            background-color: #fff;

            .title {
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                width: 200px;
                position: absolute;
                left: 40px;
                top: 50%;
                transform: translate(0, -50%);
            }

            .icon {
                cursor: grab;
                color: #999;
                font-size: 16px;
            }

            .arrow {
                // cursor: grab;
                color: #333;
                font-size: 16px;
                padding: 2px 4px;
                cursor: pointer;

            }
            .delete{
                color:#1890ff;
                font-size: 16px;
                padding: 2px 4px;
                cursor: pointer;
                margin-left: 10px;
            }
        }

        .module-content {
            background-color: #f5f5f5;
            min-height: 500px;
            padding: 5px;
        }
    }
}

.section-container {
    .page-list {

        // padding: 0 10px;
        .page-item {
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .site-collapse-custom-collapse {
            background: transparent;

            .site-collapse-custom-panel {
                margin-bottom: 0;
                overflow: hidden;
                background: transparent;
                border: 0px;
                border-radius: 2px;
            }


        }
    }

}

.clearfix::after,
.clearfix::before {
    content: "";
    display: table;
    clear: both;
}
