.classification-show-img{
    img{
    width:80px;
    height:auto;
    }
    .ant-image{
    width:80px !important;
    }
}
.information{
    .ant-pro-steps-form-container{
        min-width: 70%;
        margin: inherit;
    }
    .ant-pro-steps-form-steps-container{
        margin: inherit;
    }
}
.information-base-form{
    background: #fff;
    width: 100vw;
    padding: 40px
}

.ant-upload-list.ant-upload-list-picture-card{
    .ant-upload-disabled{
        display: none;
    }
}
.category-qualification{
    border-bottom: 2px dashed #eee;
    padding-top: 20px;
}

.files-box{
    margin: 10px 0;
    .files-title{
        margin: 10px 0 0 0;
    }
    .files-title-category{
        margin: -10px 0;
    }
}
.files-show-ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    flex-direction: column;
    margin-bottom: 0 !important;
    li{
        list-style: none;
        width: 280px;
        margin: 20px 20px 0px 0;
        .ant-image{
            width: 100%;
        }
    }
}
.category-brand-tag{
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    .ant-tag{
        margin-bottom: 8px;
    }
}



.qualification-box{
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    p{
        width: 33%;
    }
}
.brand-btn{
    padding: 0px 8px 7px 0;
    box-sizing: border-box;
    border: none;
    height: 30px;
    overflow: hidden;
    background: #fff !important;
    .ant-tag{
        margin: 0;
    }
}

.fundDetail {
    .ant-pagination {
        display: none !important;
    }
    .ant-drawer-body {
        padding: 0px !important;
    }
}
