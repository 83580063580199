.divider-title {
    font-weight: 600 !important;
    font-size: 16px !important;
    padding-left: 60px;
    margin: 10px 20px;
    box-sizing: border-box;
}
.fhdetail {
  .ant-card-body {
    padding: 0px 0px 10px !important;
  }
.pay-info {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: -10px;
    font-weight: 500;
    color: #413e3e;
    text-align: left;
    width: 100%;
    .ml10 {
        margin-left: 14px;
      }
}

}
.ipt-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 50px;
    font-size: 14px;
    .label {
        width: 100px;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
        font-weight: 600;
    }
    .value {
        display: flex;
        align-items: center;
    }
  
}

.Checked .ant-modal-footer {
    display: none;
}

.row-comu {
    display: flex;
    flex-direction: column;
    .ant-form-item {
       margin-bottom: 0px;
    }
}
