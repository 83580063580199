.ant-descriptions-item-content {
    display: flex;
    flex-direction: column;

    .rowWidth {
        display: flex;
        flex-direction: column;
        width: 100%;

        .row-item {
            width: 100%;
        }
    }
}

.toExamineDialog .ant-page-header {
    padding: 0px 20px !important;
}
