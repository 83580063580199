
.preview-container {
    overflow-x: scroll;
    .goods-scroll {
        padding: 8px;
        padding-left: 0;
        box-sizing: content-box;
        min-width: 100vw;
        .goods {
            margin-right: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            float: left;
            img {
                width: 100px;
                height: 100px;
                margin-bottom: 4px;
                border-radius: 10px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .price {
                color: #fff;
                padding: 0 8px;
                border-radius: 20px;
                background-color: #fa4b55;
                font-size: 12px;
                font-weight: bold;
                line-height: 16px;
                span { display: inline-block; }
                .l {

                }
                .cen {
                    font-size: 14px;
                    line-height: 16px;
                    margin-left: 1px;
                }
                .r {

                }
            }
        }
    }
}

.setting-container{
    padding-bottom: 20px;
    .image-list{
        padding: 0 10px;
        .image-item{
            border-radius:2px;
            overflow: hidden;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
            margin-bottom: 10px;
            .img{
                height: 135px;
                overflow: hidden;
                position:relative;
                display: flex;
                align-items: center;
                background-color: #f2f2f2;
                // .sort{
                //     width: 20px;
                //     height: 20px;
                //     border-radius:50%;
                //     color:#fff;
                //     font-size:12px;
                    
                //     background-color: rgba(0,0,0,0.6);
                //     z-index: 2;
                //     text-align: center;
                //     line-height: 20px;
                // }
                .operation{
                    position:absolute;
                    top:0px;
                    z-index: 2;
                    right:10px;
                    padding-top: 10px;
                    .icon{
                        width: 25px;
                        height: 25px;
                        border-radius:50%;
                        color:#fff;
                        font-size:14px;
                        text-align: center;
                        line-height: 25px;
                        background-color: rgba(0,0,0,0.3);
                        margin-bottom: 5px;
                    }
                }
                .img-mask{
                    position:absolute;
                    top:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                }
            }
            .info{
                padding: 10px;
                .input-group{
                    .input-item{
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
    .dragger-con{
        padding: 0 10px;
        margin-bottom: 10px;
    }
    .add-area{
        .icon{
            font-size:30px;
            color:#333;

        }
        .desc{
            font-size:12px;
            color:#999;
            margin-top: 10px;
        }
    }
}
