.details_tab{
  .ant-card-body{
    padding: 0;
  }
}

.editePwd-Layout {
  .ant-form-item-label {
    width: 90px;
  }
}
