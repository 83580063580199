.flexCss {
    display: flex;
    width: 100%;
    .flex-left {
        // width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .flex-right {
        padding-left: 10px;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.textline2 {
    width: 100%;
    white-space: pre-line;
    /*允许换行*/
    overflow: hidden;
    text-overflow: ellipsis;
    /*省略号*/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*行向垂直排列*/
    -webkit-line-clamp: 2;
    /*限制2行*/
}

.spin {
    min-height: 200px;
}

.table-wrapper {
    position: relative;
    padding: 5px 24px 24px;
    box-sizing: border-box;
    background-color: #fff;
    min-width: 1280px;
    min-height: 200px;
    .info {
        display: flex;
        flex-direction: column;
    }
   .flexCssCom {
    display: flex;
    flex-direction: column;
    padding-right: 0px !important;
    box-sizing: border-box;
    
    .flexCss {
        width: 100%;
        padding: 10px 0px;
        box-sizing: border-box;
    }
    .flexCss:not(:last-child) {
       border-bottom: 1px solid #efefef;
    }
   }
    .header {
        width: 100%;
        background-color: #F9FAFC;
        height: 46px;
        line-height: 46px;
        font-size: 14px;
        display: flex;
        justify-content: space-around;

        .col:not(:last-child) {
            border-right: 1px solid #efefef;
            text-align: center;
        }
    }

    .t-row {
        margin-top: 10px;
        .order-title {
            padding: 10px 10px;
            box-sizing: border-box;
            background-color: #F0F6FF;
            width: 100%;
            display: flex;
            line-height: 28px;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            .order-row-left {
                width: 80%;
                span {
                    margin-right: 20px;
                }
                .order-row-left-sonrow {
                    display: flex;
                    align-items: center;
                }
            }
            .order-row-center {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ff4d4f;
                font-weight: 600;
                font-size: 16px;
            }
            .order-row-right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 8.2%;
                color: #1890FF;
                span {
                    cursor: pointer;
                }
            }
        }
        .fold {
            display: flex;
            height: 40px;
            line-height: 40px;
            color: #1890FF;
            cursor: pointer;
            font-size: 14px;
          }
    }
  

    .order-row {
        width: 100%;
        display: flex;
        justify-content: space-around;
        border: 1px solid #efefef;
        border-top: none;
        font-size: 14px;
       
      
        .col {
            padding: 10px 10px 10px 0px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .col:not(:last-child) {
            border-right: 1px solid #efefef;
        }

        .good-img {
            width: 100%;
            height: 100%;
        }

    }
}

// 分页器
.btm-zw {
    width: 100%;
    height: 50px;
}
.pagination {
  width: 100%;
  position: fixed;
  right: 10px;
  padding: 10px 0px;
  bottom: 0px;
  justify-content: flex-end;
  display: flex;
  border-top: 1px solid #efefef;
  background-color: #fff;
}

// 搜索
.searchFor-wrapper {
    background-color: #fff;
    padding: 24px 24px 0px;
    min-width: 1280px
    
}
.ml10 {
    margin-left: 10px;
}

.tabs {
    height: 80px;
    width: 100%;
    background-color: #fff;
    padding: 24px 0px 0px 24px;
    display: flex;
    justify-content: space-between;
    .uploadBox{
        padding-top: 10px;
        padding-right: 25px;
    }
}
