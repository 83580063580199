.resources-urls-box {
  display: flex;
  flex-wrap: wrap;

  .left-url {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
  }

  .right-tag {
    width: 50%;
    display: flex;
    flex-wrap: wrap;

    .ant-tag {
      margin: 4px;
    }

    p {
      font-size: 12px;
    }
  }
}

.editable-proTable-table {
  .ant-card-body {
    padding: 0;
  }
}

.text-ellipsis {
  width: 300px;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.param-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
}

.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.more-box {
  width: '100%';
  background: '#fff';
  display: 'flex';
  justify-content: center;
  ;
}

.more-no {
  text-align: center;
  color: #888;
  line-height: 30px;
  height: 50px;
  background: #fff;
}

.dataDetails {
  width: 200px;
  height: 100px;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.dataComparison {
  display: flex;
  justify-content: space-between;

  .box {
    width: 49%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px;
    background: #eee;
    border-radius: 10px;

    .title {
      text-align: center;
      font-weight: bold;
    }

    .data {
      display: flex;
      flex-wrap: wrap;
      word-break: break-all;
    }

    .string {
      color: green;
      width: 60%;
    }

    .number {
      color: darkorange;
    }

    .boolean {
      color: blue;
    }

    .null {
      color: magenta;
    }

    .key {
      color: red;
      width: 35%;
    }
  }
}
