.accountcancellation {
    .ant-modal-footer, .ant-drawer-footer {
        display: none !important;
    }
    .ant-modal-body {
        padding: 20px 0px !important;
        box-sizing: border-box;
    }
}
.dealerEdite {
    position: fixed;
    right: 100px;
    top: 110px;
}

.area-wrapper {
    width: 90%;
    margin-left: 5%;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .row {
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
        &:first-child {
            border-bottom: 1px solid #eee;
        }
        .atitle {
            width: 30%;
            height: 50px;
            border-right: 1px solid #eee;
            line-height: 50px;
            padding-left: 20px;
            box-sizing: border-box;
            background-color: #F9FAFC;
            &:first-child {
                border-bottom: 1px solid #eee;
            }
        }
        .atext {
            flex: 1;
            height: 50px;
            line-height: 50px;
            padding-left: 20px;
            box-sizing: border-box;
        }
    }
}
