
.title-text-container {
    padding: 8px 15px;
    display: flex;
    align-items: flex-end;
    &.light{
        .title{
        color:#fff;
        }
        .desc{
            color:#f0f0f0;
        }
    }
    .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        width: 100%;
    }
    
    .desc {
        color: #FB501B;
        font-size: 12px;
        line-height: 20px;
        &::before{
            content:"";
            display: inline-block;
            width: 1px;
            height: 9px;
            background-color:  #D0D0D6;
            margin:0 8px;
        }
    }
}

.title-text-set{
    padding: 0 20px;
    .color-hover{
        width: 32px;
        height: 32px;
        background-color: #000000;
        border:2px solid #ccc;
        border-radius:2px;
    }
}
