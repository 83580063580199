.associated-goods .ant-card-body {
    padding: 0px !important;
}

.hideUpdate .ant-upload.ant-upload-select-picture-card {
   display: none !important;
}

.scmb0  .ant-form-item .ant-form-item-explain-error {
   margin-top: -20px;
}

.mb20 .ant-pro-form-group, .mb20 .ant-pro-form-group-container > div.ant-space-item, .mb20 .ant-pro-form-group-container, .mb20 .ant-space-item, .editor-box {
    width: 100%;
}
.editor-box, .editor-box-edit  {
    min-height: 200px !important;
}

.mb20 .ant-pro-form-group-title {
   margin-bottom: 8px !important;
   margin-top: 0px !important;
   font-weight: 400;
   color: rgba(0, 0, 0, 0.85) !important;
}
.certificatePictureUrl {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
    margin-top: -16px;
}  

