.associated-goods .ant-card-body {
    padding: 0px !important;
}

.hideUpdate .ant-upload.ant-upload-select-picture-card {
    display: none !important;
}

.scmb0 .ant-form-item .ant-form-item-explain-error {
    margin-top: -20px;
}

.mb20 .ant-pro-form-group,
.mb20 .ant-pro-form-group-container>div.ant-space-item,
.mb20 .ant-pro-form-group-container,
.mb20 .ant-space-item,
.editor-box {
    width: 100%;
}

.editor-box,
.editor-box-edit {
    min-height: 200px !important;
}

.mb20 .ant-pro-form-group-title {
    margin-bottom: 8px !important;
    margin-top: 0px !important;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85) !important;
}

.certificatePictureUrl {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
    margin-top: -16px;
}
.certificate-pop {
    .ant-modal-body {
       padding: 0px 10px 10px !important;
    }
    .ant-modal-footer {
        display: none !important;
    }
}

.cae2 {
    .certificate-text { 
        top: 120px !important;
    }
    .certicate-name {
        top: 180px !important;
    }
}

.htmltoImage-wrapper {
    width: 400px;
    position: relative;
    height: 300px;

    .certificate-img {
        position: absolute;
    }

    .certificate-text {
        font-weight: 600;
        position: absolute;
        width: 81%;
        top: 137px;
        left: 11%;
        font-size: 14px;
        text-indent: 2em;
    }

    .certicate-name {
        text-align: center;
        position: absolute;
        top: 200px;
        width: 100%;
        color: #B69866;
        font-size: 20px;
        font-family: "MyWebFont";
    }
}

@font-face {
    font-family: "MyWebFont";
    src: url("https://yumei-platform.oss-cn-hangzhou.aliyuncs.com/public/common/huangkaihuaLawyerfont-2.ttf") format("woff");
  }
