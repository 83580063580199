.title {
    font-weight: 600;
    font-size: 16px;
    margin: 0px 0px 10px 5px;
}
.wd10 {
    margin: 0px 10px;
}

.wallt-img {
   cursor: pointer;
}
