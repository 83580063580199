.related-orders-wrapper {
    display: flex;

    .related-item {
        margin-right: 20px;
        background-color: #f5f5f5;
        border-radius: 3px;
        padding: 6px 10px;
        font-size: 12px;
        box-sizing: border-box;
        cursor: pointer;
    }
}

.order-detail .ant-tabs>.ant-tabs-nav {
    margin-top: 0px !important;
}

.order-detail .ant-pro-card-body {
    padding: 0px 12px !important;
    box-sizing: border-box;
}

// .toExamineDialog .ant-modal-footer {
//     display: none;
// }
.no-data-wrapper{
    display: flex;
    justify-content: center;
    padding: 60px 0;
}
.payDetailDialog {
    .ant-card-body {
        padding: 0px !important;
    }
    .ant-modal-footer {
        display: none;
    }

    .order-money {
        display: flex;
        justify-content: space-between;
        background-color: #F9FAFC;
        height: 45px;
        padding: 0px 10px;
        box-sizing: border-box;
        align-items: center;
        margin-bottom: 10px;
    }
    .money-right {
        span {
            color: #70A8F8;
        }
       
    }
}

.payTable {
    .ant-table-cell {
         padding: 10px 0px !important;
         text-align: center;
    }
}

.order-row-center span {
    color: #444;
    font-weight: 400;
}
.order-details{
    .ant-space{
        width: 100%;
        margin-bottom: 20px;
    }
    .ant-space-item{
        width: 25%;
        line-height: 30px;
        .order-info,.buyer-seller-wrapper{
            width: 25%;
            display: flex;
            flex-direction: column;
            h3{
                font-weight: bolder;
                color: #333;
            }
        }
    }
    
}
.sellerMsg-edit{
    display: flex;
    .ant-typography-edit-content{
        margin-left: 20px;
    }
}
