@import "../../../../assets/common.less";

.image-area-container{
    
    .ant-image{
        float: left;

        .ant-image-img{
            float: left;

        }
    }
}

.image-area-set{
    // padding: 0 20px;
    width: 375px;
    margin: 0 auto;

    .input-item{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .img{
        position:relative;
        .change-img{
            width: 40px;
            height: 40px;
            border-radius:50%;
            color:#fff;
            font-size:18px;
            text-align: center;
            line-height: 40px;
            background-color: rgba(0,0,0,0.3);
            position:absolute;
            top:10px;
            z-index: 2;
            left:10px;
            cursor: pointer;
        }
        .preview{
            position:absolute;
            right:10px;
            top:20px;
            z-index: 2;

        }
        .hot-area-list{
            position:absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            .item{
                position:absolute;
                background-color: rgba(0,0,0,0.6);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius:4px;

                &.selected{
                    background-color: rgba(255, 117, 11,0.6);
                    border:2px solid #1890ff;
                    // border-radius:4px;
                    
                }
                .delete{
                    text-align: center;
                    color:#fff;
                    font-size:14px;
                    cursor: pointer;
                }
    
            }
        }
    }
    .custom-form{
        .input-item{
            display: flex;
            align-items: center;
        }
    }
    .dragger-con{
        padding: 20px 10px;
        margin-bottom: 10px;
        border:1px dashed #eee;
        border-radius:8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .add-area{

            display: flex;
            flex-direction: column;
            align-items: center;
        }
            .icon{
                font-size:30px;
                color:#333;
    
            }
            .desc{
                font-size:12px;
                color:#999;
                margin-top: 10px;
            }
    }
}
