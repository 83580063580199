.associated-goods .ant-card-body {
    padding: 0px !important;
}

.hideUpdate .ant-upload.ant-upload-select-picture-card {
    display: none !important;
}

.scmb0 .ant-form-item .ant-form-item-explain-error {
    margin-top: -20px;
}

.mb20 .ant-pro-form-group,
.mb20 .ant-pro-form-group-container>div.ant-space-item,
.mb20 .ant-pro-form-group-container,
.mb20 .ant-space-item,
.editor-box {
    width: 100%;
}

.editor-box,
.editor-box-edit {
    min-height: 200px !important;
}

.mb20 .ant-pro-form-group-title {
    margin-bottom: 8px !important;
    margin-top: 0px !important;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85) !important;
}


// 素材页面
.warpper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px);
    border: 16px solid #f0f0f0;
    overflow: hidden;
    .top-warpper {
        padding-left: 0px;
        box-sizing: border-box;
        background-color: #fff;
        .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
        .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
            padding-left: 30px;
            box-sizing: border-box;
        }

        .ant-tabs-top>.ant-tabs-nav {
            margin: 0px;
        }
    }

    .bottom-warpper {
        width: 100%;
        display: flex;
        flex: 1;
        position: relative;

        .b-left-warpper {
            background-color: #fff;
            width: 18%;
            min-width: 280px;
            border-right: 1px solid #f0f0f0;
            padding: 20px 0px 0px;
            box-sizing: border-box;

            .node-row {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .ant-tree .ant-tree-node-content-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
            }

            .ant-tree .ant-tree-treenode,
            .ant-tree-title {
                width: 100%;
            }

            .ant-tree .ant-tree-treenode {
                margin: 5px 0px;
            }

            .treeMenu-wrapper {
                position: relative;
                height: calc(100vh - 150px);
                .menufooter {
                    height: 70px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    border-top: 1px solid #f0f0f0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .addBtn {
                        width: 90%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #f0f0f0;
                        height: 40px;
                        border-radius: 2px;
                    }
                }
            }
        }

        .b-right-warpper {
            flex: 1;
            background-color: #fff;
            .content {
                padding: 0px;
            }
        }
    }

}
