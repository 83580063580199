.notice-container{
    // padding: 0 10px;
    // margin:4px 0;
    .notice-con{
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 0 10px;
        // border-radius:4px;
        .icon{
            width: 20px;
        }
        .carousel-con{
            width: 300px;
            margin-left: 5px;
            height: 40px;
            overflow: hidden;
            position:relative;
            .list{
                position:absolute;
                width: 100%;
                transition:all .3s ease-in-out;
                .item{
                    line-height: 40px;
                }
            }
        }
    }
}

.notice-set-container{
    padding: 0 10px;
    
    .color-set-cont{
        display: flex;
        align-items: center;
        .color-hover{
            width: 32px;
            height: 32px;
            background-color: #000000;
            border:2px solid #ccc;
            border-radius:2px;
            margin-right: 4px;
        }
    }
    .add{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .image-item{
        padding: 15px 0;
        border-bottom:1px solid #f0f0f0;

        .input-group{
            .input-item{
                display: flex;
                align-items: center;
                margin-top: 10px;
            }
        }
        .operation{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 10px;

            .icon{
                width: 25px;
                height: 25px;
                border-radius:50%;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 25px;
                background-color: rgba(0,0,0,0.3);
                margin-bottom: 5px;
                cursor: pointer;
                margin-left: 10px;
            }
        }

    }
}
