@import "../../../../assets/common.less";

.list-nav-container {

    width: 100%;
    overflow: scroll;
    height: 680px;
    position: relative;
    &.light{
        .list-nav-tabs{
            .ant-tabs-nav{
                .ant-tabs-ink-bar{
                    background-color: #fff;
                }
                .ant-tabs-tab-btn{
                    color:#fff;
                }
            }
            .anticon-ellipsis{
                color:#fff!important;
            }
        }
        .no-more{
            color: #fff!important;
        }
    }
    .list-nav-tabs {
        .ant-tabs-nav {
            margin-bottom: 0;
            // position: absolute;
            // top: -5px;
            // left: 0;
            width: 100%;
            z-index: 2;
            background-color: transparent;
            &::before {
                border: none;
            }

            .ant-tabs-nav-wrap {
                padding-left: 14px;

                .ant-tabs-ink-bar {}

                .ant-tabs-tab {
                    margin-left: 14px;
                    padding: 5px 0;

                    &:nth-child(1) {
                        margin-left: 0;
                    }

                    &.ant-tabs-tab-active {
                        // .ant-tabs-tab-btn{
                        //     font-size: 18px;
                        //     font-weight: bold;
                        //     color: #101012;
                        //     line-height: 25px;
                        // }
                    }

                    .ant-tabs-tab-btn {
                        // font-size: 16px;
                        // color: #797A80;
                        // line-height: 22px;
                    }
                }

            }
        }

        .goods-list-container {
            width: 100%;
            height: 100%;
            // position: absolute;
            // top: 0;
            // left: 0;
            overflow: scroll;
            // padding-top: 28px;

            .no-more {
                text-align: center;
                font-size: 12px;
                color: #888;
                line-height: 30px;
            }

            .loading-con {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
            }

        }
    }
}


.list-nav-set {
    padding-bottom: 20px;

    .set-form {
        padding: 0 10px;
    }
    
    .goods-set-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        padding-bottom: 10px;
        border-bottom:1px solid #eee;
        .left{
            color:#666;
            font-size:14px;
        }
    }
    .image-list {
        padding: 0 10px;

        .image-item {
            border-radius: 4px;
            overflow: hidden;
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #eee;
            padding: 10px 0;

            .form-con{
                width: 100%;
                .desc{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .operation {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 10px;

                .icon {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    color: #444;
                    font-size: 14px;
                    text-align: center;
                    line-height: 25px;
                    // background-color: rgba(0,0,0,0.3);
                    // margin-bottom: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    .dragger-con {
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .add-area {
        .icon {
            font-size: 30px;
            color: #333;

        }

        .desc {
            font-size: 12px;
            color: #999;
            margin-top: 10px;
        }
    }
}

.drawer-container{
    .goods-list-con{
        .goods-item{
            overflow: hidden;
            display: flex;
            position:relative;
            border-bottom:1px solid #eee;
            padding: 10px 0;
            &:nth-last-child(1){
                border:none;
            }
            .img{
                width: 100px;
                height: 100px;
                overflow: hidden;
                position:relative;
                border-radius:4px;
                display: flex;
                align-items: center;
                background-color: #f2f2f2;
                .number{
                    position:absolute;
                    left:5px;
                    top:5px;
                    z-index: 2;
                    min-width: 25px;
                    height: 25px;
                    padding: 0 4px;
                    border-radius:12px;
                    color:#fff;
                    font-size:14px;
                    text-align: center;
                    line-height: 25px;
                    background-color: rgba(0,0,0,0.6);
                    margin-bottom: 11px;
                }
            }
            .info{
                width: 200px;  
                padding-left: 10px;
                position:relative;
                .name{
                    font-size:13px;
                    line-height: 20px;
                    margin-bottom: 6px;

                }           
                .price{
                    font-size:13px;
                    font-weight: bold;
                    color:@mainColor;
                    position:absolute;
                    bottom:15px;
                    left:10px;
                    margin-bottom: 0;
                }   
            }
            .operation{
                position:absolute;
                top:0px;
                z-index: 2;
                right:0px;
                padding-top: 10px;
                .icon{
                    width: 25px;
                    height: 25px;
                    border-radius:50%;
                    color:#fff;
                    font-size:14px;
                    text-align: center;
                    line-height: 25px;
                    background-color: rgba(0,0,0,0.3);
                    margin-bottom: 11px;
                }
            }
        }
    }

}
