.related-orders-wrapper {
    display: flex;
    .related-item {
        margin-right: 20px;
        background-color: #f5f5f5;
        border-radius: 3px;
        padding: 6px 10px;
        font-size: 12px;
        box-sizing: border-box;
        cursor: pointer;
    }
}

.order-detail .ant-tabs > .ant-tabs-nav {
    margin-top: 0px !important;
}

.order-detail .ant-pro-card-body {
    padding: 0px 12px !important;
    box-sizing: border-box;
}
