.config-set{
    padding: 0 10px;
    
    .color-set-cont{
        display: flex;
        align-items: center;
        .color-hover{
            width: 32px;
            height: 32px;
            background-color: #000000;
            border:2px solid #ccc;
            border-radius:2px;
            margin-right: 4px;
        }
    }
}
