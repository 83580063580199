
.left20 {
    margin-bottom: 10px;
}
.flexright {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.isqz {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 100px;
    .title-name {
        font-size: 14px;
        margin-right: 10px;
        font-weight: 600;
    }
   
}

.user-info {
    display: flex;
    align-items: center;
    .avator {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 10px;
    }
    p {
        line-height: 22px;
    }
}


.data-statistics {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .item {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        font-size: 14px;

        .data-name {
            color: #333;
        }

        .data-count {
            color: #333;
            font-weight: 600;
        }
    }
}

.mbtm20 {
    margin-bottom: 20px;
    .data-row {
        display: flex;
        align-items: center;
        .avator {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}

.fz-label {
    background-color: #EBF5FF;
    font-size: 11px;
    color: #3399FF;
    padding: 2px 8px;
}

.leveBox {
    display: flex;
    margin-top: 10rpx;
    flex-wrap: wrap;
    word-break: break-all;

    .leveItem {
        display: flex;
        align-items: center;
        font-family: PingFangSC-Bold, PingFang SC;
        font-weight: bold;
        color: #2e2e2e;
        border-radius: 30px;
        margin-left: 5px;
    }

    .levelName {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 30px;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        padding-right: 8px;
        margin-left: 5px;
    }
}

.alignBaseline {
    display: flex;
    align-items: baseline;
}

.ant-space-item {
    display: flex;
    align-items: center;
}

.row-column {
    display: flex;
    flex-direction: column;

    .row-section {
        display: flex;
        flex-direction: row;
    }
}

.acimg {
    cursor: pointer;
    vertical-align: middle;

    img {
        width: 100%;
    }
}

.wid100 {
    width: 100%;
}

.selectBox {
    padding: 20px 0 0 20px;
    background: #fff;
    box-sizing: border-box;
}

.hideone {
    white-space: nowrap;
    /*强制在一行显示*/
    overflow: hidden;
    /*溢出部分隐藏不可见*/
    text-overflow: ellipsis;
    /*显示出省略号*/
    width: 150px;
}

.ant-input-group.ant-input-group-compact {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
}

.ant-pro-form-light-filter .ant-form-item {
    margin-bottom: 5px;
}

.info {
    padding-left: 24px;
    margin-bottom: 20px;
}

.contractDialog .ant-modal-footer {
    display: none;
}

.info-wrapper {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 20px;
    word-break: break-all;

    .boldlabel {
        font-weight: 600;
    }

    .info-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        word-break: break-all;
    }
}

.shModel .ant-modal-body {
    padding-top: 10px;
    box-sizing: border-box;
}

.redColor {
    color: coral;
}

.alignCenter {
    display: flex;
    align-items: center;
    margin-top: 6px;
}

.wid100 .ant-form-item {
    margin-bottom: 0px;
}

.source-name {
    display: flex;
    flex-direction: column;
}

.hide-lineone {
    white-space: nowrap;
    /*强制在一行显示*/
    overflow: hidden;
    /*溢出部分隐藏不可见*/
    text-overflow: ellipsis;
    /*显示出省略号*/

}

li {
    list-style: none;
}

.userInfoBox {
    span {
        font-weight: bold;
        color: #444;
    }
}

.LLBox {
    margin-top: 40px;

    .ant-btn {
        margin-top: 20px;
    }
}

.cancel_account_text {
    margin-top: 20px;
    font-size: 12px;
    color: #ee2222;
    line-height: 20px;
}
