.image-carousel-bg-con {
    position: relative;
    overflow: hidden;

    .image-carousel-bg-image {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.image-carousel {
    margin: 0 auto;
    width: 355px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
    background-color: #f2f2f2;

    &.simple {
        .img {
            height: auto;
        }

        &.is-group {
            border-radius: 12px 12px 0 0;
        }
    }

    &.holder {
        .img {
            height: 135px;
        }
    }

    .img {
        width: 100%;
        display: flex !important;
        align-items: center;
        // height: 135px;
    }

    .dot-list {
        bottom: 8px;
        margin-bottom: 0;

        li {
            width: 4px;
            height: 2px;
            border-radius: 1px;
            background-color: rgba(255, 255, 255, 0.5);
            margin: 0;
            margin-right: 2px;

            &:nth-last-child(1) {
                margin-right: 0;
            }

            &.slick-active {
                width: 8px;
                background-color: #fff;
            }
        }
    }
}

.image-carousel-set {
    padding-bottom: 20px;

    .image-list {
        padding: 0 10px;

        .image-item {
            border-radius: 2px;
            overflow: hidden;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            margin-bottom: 10px;

            .img {
                height: 135px;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                background-color: #f2f2f2;
                // .sort{
                //     width: 20px;
                //     height: 20px;
                //     border-radius:50%;
                //     color:#fff;
                //     font-size:12px;

                //     background-color: rgba(0,0,0,0.6);
                //     z-index: 2;
                //     text-align: center;
                //     line-height: 20px;
                // }
                .change-img {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    line-height: 25px;
                    background-color: rgba(0, 0, 0, 0.3);
                    margin-bottom: 5px;
                    position: absolute;
                    top: 10px;
                    z-index: 2;
                    left: 10px;
                    cursor: pointer;
                }

                .operation {
                    position: absolute;
                    top: 0px;
                    z-index: 2;
                    right: 10px;
                    padding-top: 10px;

                    .icon {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                        line-height: 25px;
                        background-color: rgba(0, 0, 0, 0.3);
                        margin-bottom: 5px;
                        cursor: pointer;
                    }
                }

                .img-mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .info {
                padding: 10px;

                .input-group {
                    .input-item {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    .dragger-con {
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .add-area {
        .icon {
            font-size: 30px;
            color: #333;

        }

        .desc {
            font-size: 12px;
            color: #999;
            margin-top: 10px;
        }
    }
}
